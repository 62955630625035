import React from 'react'
import { graphql, Script } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block06'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import GetStarted from '@solid-ui-blocks/Stats/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import FAQ from '@solid-ui-blocks/Faq/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ContentText from '@solid-ui-components/ContentText'
import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Notify, Remind, and Engage – Send SMS from Google Sheets In Seconds' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['feature-tab-one']} reverse />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabTwo content={content['feature-tab-two']} />
        <Divider space='5' />
        <Divider space='5' />
        <FeatureTabThree content={content['feature-tab-three']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='full' sx={styles.testimonialsContainer}>
        <Testimonials content={content['testimonials']} />
      </Container>
      <FAQ content={content['faq']} />      
      <Divider space='5' />
      <GetStarted content={content['get-started']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container sx={{ textAlign: `center` }}>
        <ContentText content={[{ text: 'Featured Blog Posts', variant: 'h2'}]} />
        <WithRecentPosts>
          <Blog content={content['latest-blogs']} />
        </WithRecentPosts>
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Footer content={content['footer']} />
      <Script>
        {`
          (function(){if(!window.chatbase||window.chatbase("getState")!=="initialized"){window.chatbase=(...arguments)=>{if(!window.chatbase.q){window.chatbase.q=[]}window.chatbase.q.push(arguments)};window.chatbase=new Proxy(window.chatbase,{get(target,prop){if(prop==="q"){return target.q}return(...args)=>target(prop,...args)}})}const onLoad=function(){const script=document.createElement("script");script.src="https://www.chatbase.co/embed.min.js";script.id="wHqRDDTpLj4HJJArxRcp1";script.domain="www.chatbase.co";document.body.appendChild(script)};if(document.readyState==="complete"){onLoad()}else{window.addEventListener("load",onLoad)}})();
        `}
      </Script>
      <Script>
        {`
            !function () {var reb2b = window.reb2b = window.reb2b || [];
              if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];
              reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);
              args.unshift(method);reb2b.push(args);return reb2b;};};
              for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}
              reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;
              script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
              var first = document.getElementsByTagName("script")[0];
              first.parentNode.insertBefore(script, first);};
              reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("M5NRP9H150O1");}();
        `}

      </Script>
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/saas", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
