import React, { useState } from 'react'
import { Container, Flex, Box, Badge, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Tabs from '@solid-ui-components/Tabs'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  middleBox: {
    position: `relative`,
    zIndex: 2
  },
  prevPrice: {
    textDecoration: `line-through`,
    opacity: 0.2
  },
  saveBadge: {
    position: `absolute`,
    top: -2,
    right: -2
  }
}

const PricingBlock01 = ({ content: { text, collection, buttons } }) => {
  const [plan, setPlan] = useState(0)

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text?.slice(0, 3)} />
      {text?.[3]?.textGroup && (
        <>
          <Divider space={3} />
          <Tabs tabs={text[3].textGroup} onChange={setPlan} variant='pill' />
        </>
      )}
      {collection && (
        <Flex
          sx={{
            flexWrap: `wrap`,
            justifyContent: `center`,
            mx: -3,
            height: '100%'
          }}
        >
          {collection.map(
            ({ container, text, icon, collection, buttons }, i) => (
              <Box
                key={`item-${i}`}
                sx={{ 
                  flex: [`auto`, 1], 
                  minWidth: 320, 
                  maxWidth: 500, 
                  p: 3,
                  height: '100%',
                  '& > div': {
                    height: '100%'
                  },
                  '& > div > div': {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }
                }}
              >
                <Reveal
                  effect={
                    collection.length === 3
                      ? i === 0
                        ? 'fadeInDeepRight'
                        : i === collection.length - 1
                        ? 'fadeInDeepLeft'
                        : null
                      : 'fadeInUp'
                  }
                  css={css(
                    i !== 0 && i !== collection.length - 1 && styles.middleBox
                  )}
                >
                  <ContentContainer
                    content={container}
                    variant='cards.paper'
                    sx={{ position: `relative` }}
                  >
                  {text?.[1]?.textGroup?.[0] === "$49" && (
                    <Reveal effect='fadeInRight' css={css(styles.saveBadge)}>
                        <Badge variant='tag-yellow'>
                          Most Popular
                        </Badge>
                      </Reveal>
                    )}
                    <ContentText content={text?.[0]} />
                    <Flex
                      sx={{ alignItems: `center`, justifyContent: `center` }}
                    >
                      <ContentText
                        content={{
                          ...text?.[1],
                          text:
                            plan > 0
                              ? text?.[1]?.textGroup?.[0]
                              : text?.[1]?.textGroup?.[plan]
                        }}
                        mb='0'
                        mr='2'
                        ml='0'
                        sx={{
                          transition: `all .4s ease-in`,
                          ...(plan > 0 && styles.prevPrice)
                        }}
                      />
                      {plan > 0 && (
                        <Reveal effect='fadeInDeepLeft'>
                          <ContentText
                            content={{
                              ...text?.[1],
                              text: text?.[1]?.textGroup?.[plan]
                            }}
                            mb='0'
                            mr='2'
                          />
                        </Reveal>
                      )}
                      <ContentText content={text?.[2]} mb='0' mt='2' />
                    </Flex>
                    <Flex
                      mt='2'
                      mb='0'
                      sx={{ alignItems: `center`, justifyContent: `center` }}
                    >
                    <ContentText content={{ ...text?.[3], text: text?.[3]?.textGroup?.[plan] }} mr='1' mb='0' />
                    <ContentText content={text?.[4]} mb='0' />
                    </Flex>
                    {collection && (
                      <>
                        <Divider space={2} />
                        {collection.map((props, index) => {
                          const description = props.text?.[1]?.text;
                          
                          return (
                            <ListItem 
                              key={`item-${index}`} 
                              {...props} 
                              compact
                              text={[props.text?.[0]]}
                              description={description}
                              sx={{ 
                                fontSize: 1,
                                lineHeight: 'normal',
                                textAlign: 'left',
                                '& p': {
                                  fontSize: 1,
                                  mb: 0,
                                  textAlign: 'left'
                                },
                                '& > div': {
                                  textAlign: 'left',
                                  flex: 1
                                }
                              }} 
                            />
                          );
                        })}
                      </>
                    )}
                    {buttons && (
                      <>
                        <Divider space={3} />
                        <ContentButtons content={buttons} />
                      </>
                    )}
                  </ContentContainer>
                </Reveal>
              </Box>
            )
          )}
        </Flex>
      )}
      <ContentButtons content={buttons} />
    </Container>
  )
}

export default WithDefaultContent(PricingBlock01)
