/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    position: `relative`,
    pt: [5, 6],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: [`full`, `90%`],
      height: `80%`,
      top: [`-10%`, 0],
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: `linear-gradient(164deg, #9affb3, #fefb7f)`,
      backgroundSize: `100%`
    },
    '::after': {
    }
  },
  featuresContainer: {
    position: `relative`,
    py: [5, 6],
    '::before': {
      position: `absolute`,
      content: `" "`,
      size: `full`,
      top: 0,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1,
      borderRadius: `xl`,
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 80%,
          ${t.colors.omegaLight} 100%
        )`
    }
  },
  testimonialsContainer: {
    position: `relative`,
    py: [5, 6],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: [`full`, `90%`],
      height: `80%`,
      top: 0,
      right: `50%`,
      transform: `translate(50%, 0)`,
      zIndex: -1
    },
    '::before': {
      borderRadius: `xl`,
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 50%,
          ${t.colors.omegaLight} 100%
        )`
    },
  },
  faqContainer: {
    position: `relative`,
    py: [5, 6],
      background: t => `linear-gradient(
          150deg,
          ${t.colors.omegaLighter} 80%,
          ${t.colors.omegaLight} 100%
        )`

  }
}
